<template>
  <div class="about-page">
    <div class="rn-story-area d-flex bg_color--3" id="about">
      <div
        class="rn-story-left w-50 rn-story-bg-wrapper"
        :style="{ backgroundImage: 'url(' + aboutSrc + ')' }"
      ></div>
      <div class="rn-story-right w-50 d-flex align-items-center">
        <div
          class="story-style--3 story-content text-left rn-plr--160 section-ptb-xl"
        >
          <div class="content text-left">
            <h2>
              Refreshingly Unique <br />
              Child Engagement.
            </h2>
            <p>
              With decades of experience in healthcare, as a creator, and as a
              grandmother to many grandkids, Joanie connects with children
              everywhere.
              <br /><br>
              From an echcardiology sonographer for over 50 years including
              years at the Mayo Clinic, she transitioned naturally from the art
              of clinical imaging to hand painting birdhouses for use and or
              display.
              <br /><br>
              Discover the enchanting world of Joanie's creations, where unique
              imagination takes flight in each piece is a testiment to Joanie's
              artisitic flair. Each piece is showcasing intricate details and a
              touch of magic. Enjoy joanies workshops, and after school
              programs. Find the etsy links below.
            </p>
            <div
              class="story-btn wow newFadeInUp"
              data-wow-duration="1s"
              data-wow-delay="1000ms"
            >
              <router-link to="/contact">Get Connected</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Story Area -->
    <!-- Start Skill Area -->
    <div class="rn-skill-area home-one-skill d-flex bg_color--3" id="skill">
      <div class="rn-skill-right w-50 d-flex align-items-center">
        <div class="text-left rn-skill rn-plr section-ptb-xl etsy-wrap">
          <img class="etsy-heart" src="../../assets/img/etsy-logo-heart.png" />
          <br />
          <p><b>View Joanie's works at the links below.</b></p>
          <div id="shop-categories"></div>
        </div>
      </div>
      <div
        class="rn-skill-left w-50 rn-skill-bg-wrapper"
        :style="{ backgroundImage: 'url(' + skillSrc + ')' }"
      ></div>
    </div>
    <!-- End Skill Area -->
    <!-- <div id="listings"> </div> -->
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      aboutSrc: require("../../assets/img/joanInClassCraft750x860.jpg"),
      skillSrc: require("../../assets/img/FluidPainting960x950.jpeg"),
    };
  },
};
</script>

<style lang="scss" scoped>
.about-page {
  .rn-skill-right {
    @media only screen and (max-width: 767px) {
      order: 2;
    }
  }
}
.etsy360-logo {
  display: none;
}
</style>

<style lang="css" scoped>
.etsy360-logo {
  display: none;
}
</style>

